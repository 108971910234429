<template>
  <layout-header></layout-header>
  <breadcrumb :title="title" :text="text" :text1="text1" />
  <section class="section car-listing">
    <div class="container">
      <div class="row" >
        <div class="col-lg-12">
          <div class="row">
            <div
                class="col-lg-4 col-md-6 col-12 d-flex"
                v-for="item in listing_grid"
                :key="item.id"
            >
              <div class="listing-item">
                <div class="listing-img">
                  <a :href="(`/listing/listing-details${item.id}`)">
                    <img
                        :src="require(`@/assets/img/cars/${item.CarImage}`)"
                        class="img-fluid"
                        alt="Toyota"
                    />
                  </a>

                </div>
                <div class="listing-content">
                  <div class="listing-features">

                    <h3 class="listing-title">
                      <a :href="(`/listing/listing-details${item.id}`)">{{
                          item.Name
                        }}</a>
                    </h3>

                  </div>
                  <div class="listing-details-group">
                    <ul>
                      <li>
                        <span
                          ><img src="@/assets/img/icons/wifi.svg" alt="WiFi"
                        /></span>
                        <p>{{ item.transmission }}</p>
                      </li>
                      <li>
                        <span
                          ><img src="@/assets/img/icons/car-parts-02.svg" alt="10 KM"
                        /></span>
                        <p>{{ item.mileage }}</p>
                      </li>
                      <li>
                        <span
                          ><img src="@/assets/img/icons/car-parts-03.svg" alt="Petrol"
                        /></span>
                        <p>{{ item.fuelType }}</p>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <span
                          ><img src="@/assets/img/icons/car-parts-04.svg" alt="Power"
                        /></span>
                        <p>{{ item.power }}</p>
                      </li>
                      <li>
                        <span
                          ><img src="@/assets/img/icons/car-parts-05.svg" alt="2018"
                        /></span>
                        <p>{{ item.year }}</p>
                      </li>
                      <li>
                        <span
                          ><img src="@/assets/img/icons/car-parts-06.svg" alt="Persons"
                        /></span>
                        <p>{{ item.passengers }}</p>
                      </li>
                    </ul>
                  </div>
                  <div class="listing-location-details">
                    <div class="listing-price">
                      <span><i class="feather-map-pin"></i></span>{{ item.location }}
                    </div>
                    <div class="listing-price">
                      <h6>{{ item.price }} <span>/ Day</span></h6>
                    </div>
                  </div>
                  <div class="listing-button">
                    <a :href="(`/listing/listing-details${item.id}`)" class="btn btn-order"
                    ><span><i class="feather-calendar me-2"></i></span>Rent
                      Now</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--Pagination-->

          <!--/Pagination-->
        </div>
      </div>
    </div>
  </section>
  <layout-footer></layout-footer>
</template>
<script>
import listing_grid from "@/assets/json/listing-grid.json";
export default {
  data() {
    return {
      listing_grid: listing_grid,
      title: "Our Fleet",
      text: "Our Fleet",
    };
  },
  methods: {
    toggleSelected(item) {
      item.isSelected = !item.isSelected;
    },
  },
};
</script>