<template>
  <div class="col-lg-4 theiaStickySidebar">
    <div class="stickysidebar">
      <div class="review-sec mt-0">

        <div class="review-header">
          <h4>Check Availability</h4>
        </div>
        <div class="">
          <form id="Combo">
            <ul>
              <li class="column-group-main">
                <div class="input-block m-0">
                  <label>Pickup Date and Time</label>
                </div>
                <div class="input-block-wrap sidebar-form">
                   <div class="input-block me-2">
                  <div class="group-img">
                    <input type="date" id="date" name="date" min="new Date()" required style="margin: 0.5rem; padding: 0.8rem; width: 80%; border-radius: 5px; background:#FCFBFB; border:1px solid #F4F4F4;">

                  </div>
                </div>
                  <div class="input-block me-2">
                    <div class="group-img">
                      <input type="time" id="time" name="time" required style="margin: 0.5rem; padding: 0.8rem; width: 80%; border-radius: 5px; background:#FCFBFB; border:1px solid #F4F4F4;" min="10:00" max="16:00">

                    </div>

                  </div>
                </div>
              </li>
              <li class="column-group-main">
                <div class="input-block">
                  <label>Choose Package</label>
                  <div class="input-block me-2">

                    <div class="form-group">
                      <select id="option" class="form-control" name="option" required>
                        <option style="display:none"></option>
                        <option>4-Hours</option>
                        <option>8-Hours</option>
                      </select>
                    </div>
                    <label>Number of persons</label>
                    <div class="group-img">
                      <input
                          class="form-control"
                          placeholder=""
                          type="text"
                          name="persons"
                          required
                      />
                    </div>
                  </div>
                </div>
                <div class="input-block">
                  <label>Choose Combo</label>
                  <div class="input-block me-2">

                    <div class="form-group">
                      <select id="option" class="form-control" name="boats" required>
                        <option style="display:none"></option>
                        <option>Valory 525 + Valory 480</option>
                        <option>Valory 525 + Cobra 495</option>
                        <option>Cobra 495 + Valory 480</option>
                      </select>
                    </div>

                  </div>
                </div>
                <div class="input-block">
                  <div class="form-check">
                    <label class="form-check-label" for="exampleCheck1">Do you need skippers? (additional charge
                      +70€/skipper)</label>
                    <input id="exampleCheck1" class="form-check-input" name="skipper" type="checkbox">
                  </div>
                  <div class="form-check">
                    <label class="form-check-label" for="exampleCheck1">Do you need a SUP? (additional charge +20€)</label>
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" name="sup">
                  </div>
                </div>
              </li>

              <li class="column-group-main">
                <div class="input-block">
                  <label>Full Name</label>
                  <div class="group-img">
                    <input
                        class="form-control"
                        name="name"
                        placeholder=""
                        type="text"
                        required
                    />
                  </div>
                  <label>E-mail</label>
                  <div class="group-img">
                    <input
                        class="form-control"
                        name="email"
                        placeholder=""
                        type="Email"
                        required
                    />
                  </div>
                  <label>Phone Number</label>
                  <div class="group-img">
                    <input
                        class="form-control"
                        name="number"
                        placeholder=""
                        type="tel"
                        required
                    />
                  </div>
                </div>
              </li>


              <li class="column-group-last">
                <div class="input-block mb-0">
                  <div class="search-btn">
                    <button class="btn btn-primary check-available w-100">
                      Check Availability
                    </button>

                  </div>
                </div>
              </li>
            </ul>
          </form>
        </div>
      </div>
      <div class="review-sec share-car mt-0">
        <div class="review-header">
          <h4>Our Price List</h4>
        </div>
        <div class="price-list">
          <table>
            <thead>
            <tr>
              <th>Boats</th>
              <th>Service</th>
              <th>Price</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in priceList" :key="index">
              <td>{{ item.boats }}</td>
              <td>{{ item.service }}</td>
              <td>{{ item.price }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {ref} from 'vue'

const currentDate1 = ref(new Date());
//import { add } from 'date-fns'

export default {
  data() {
    return {
      startdate1: currentDate1,
      dateFormat: "dd-MM-yyyy",
      priceList: [
        {boats: 'Valory 525 + Valory 480', service: 'Boat Rental - 4 Hour', price: '460€'},
        {boats: 'Valory 525 + Valory 480', service: 'Boat Rental - 8 Hour', price: '760€'},
        {boats: 'Valory 525 + Cobra 495', service: 'Boat Rental - 4 Hour', price: '490€'},
        {boats: 'Valory 525 +  Cobra 495', service: 'Boat Rental - 8 Hour', price: '799€'},
        {boats: ' Cobra 495 + Valory 480', service: 'Boat Rental - 4 Hour', price: '399€'},
        {boats: ' Cobra 495 + Valory 480', service: 'Boat Rental - 8 Hour', price: '690€'},
      ]
    };
  }
};
//const disabledDate1 = ref(new Date("2024-5-20"))
//<!--:disabledDates="{ dates: [disabledDate1,new Date('2024-5-28')] }" -->



// let disabledDate = ref((new Date()))[]
// disabledDate.value = new Date("2024-5-20")+new Date("2024-5-22");


</script>
<style scoped>
.price-list {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.price-list h1 {
  text-align: center;
  margin-bottom: 20px;
}

.price-list table {
  width: 100%;
  border-collapse: collapse;
}

.price-list th, .price-list td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.price-list th {
  background-color: #f2f2f2;
}
</style>