<template>
  <layout-header></layout-header>
  <breadcrumb :title="title" :text="text" :text1="text1" />
  <section class="privacy-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="terms-policy">
            <h4>1. Lessor Obligations</h4><br>
            <p data-aos="fade-down">
              1.1 The Lessor must deliver the boat ready and clean for use, filled with 25-30L of fuel and an extra 5L and accompanied by all the necessary and prescribed equipment</p>
            <p data-aos="fade-down">1.2 The Lessor must have already insured the vessel covering the charter dates. Please note that the insurance does not cover any damage to the boat, its equipment, its engines and its accessories (leg, propellers, etc.)
            </p>
            <h4>2. Lessee Obligations</h4><br>
            <p data-aos="fade-down">2.1 The Lessee is obliged to return the vessel to the agreed point and in the condition in which he received it, at the end of the charter period as stated above and at least two hours before sunset</p>
            <p data-aos="fade-down">2.2 The Lessee is obliged to pay the sum of two hundred euros (€200.00) as a guarantee for accidental damages, either by credit card, cash or bank transfer, upon the start of the charter. The deposit is returned at the end of the charter and after the inspection of the vessel, after it is found that it is in the condition that was delivered to the Lessee</p>
            <p data-aos="fade-down">2.3 In case the vessel is delivered to a location other than the one agreed, the Lessee owes compensation to the Lessor according to the distance between the place of agreed and actual delivery. The compensation will be calculated as €10.00 per nautical mile and will in no case be less than €400.00.</p>
            <p data-aos="fade-down">2.4 In the case of delivery of the vessel by the Lessee at a time earlier than that agreed upon, the charterer is not entitled to a refund.
              In the case of delivery of the vessel by the Lessee at a time later than the one agreed upon, the Lessee will be charged the amount of one hundred euros (€100.00) per hour of delay from the agreed delivery time to the Lessor</p>
            <p data-aos="fade-down">"2.5 The Lessee declares that he undertakes to use the vessel with prudence and care.
              He also undertakes not to use the boat in the cases of a) Limited visibility b) In the event of wind over 5Bf and in the event that the port authority has a sailing ban c) In the event that the number of people boarding the boat is greater than the permitted number d) In the event of fatigue , drunkenness, illness. The use of the vessel for racing and any form of towing is prohibited."</p>
            <p data-aos="fade-down">2.6 Any fines imposed by port authorities resulting from unauthorized or dangerous use of the vessel shall be borne exclusively by the Lessee. He is also liable to the Lessor for any actions of the other occupants of the vessel that may cause damage to the vessel or to third parties.           </p>
            <p data-aos="fade-down">2.7 It is prohibited to give the boat to a third party with or without consideration, as well as to carry out any illegal act using the boat.</p>
            <p data-aos="fade-down">2.8 The refueling is undertaken by the Lessor in the presence of the Lessee The cost of fuel is borne exclusively by the Lessee</p>
            <p data-aos="fade-down">2.9 If any accident or damage is caused by the Lessee you are required to contact the nearest port authority for a written declaration of the accident.</p>
            <p data-aos="fade-down">2.10 The Lessee-skipper is responsible for any minor or major damage that may be caused to the vessel's hull, equipment or engines. Damages are abrasions, breakages, losses and in general that deviates from the condition of delivery, including the amount of fuel during delivery. The Lessee is responsible for repairing the damage or compensating the value of the vessel in case of total destruction.</p>

          </div>
        </div>
      </div>
    </div>
  </section>
  <layout-footer></layout-footer>
</template>

<script>
export default {
  data() {
    return {
      title: "Terms & Conditions",
      text: "Pages",
      text1: "Terms & Conditions",
    };
  },
};
</script>
