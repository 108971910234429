<template>
  <section class="section why-choose">
    <div class="choose-left">
      <img src="@/assets/img/bg/choose-left.png" class="img-fluid" alt="Why Choose Us" />
    </div>
    <div class="container">
      <!-- Heading title-->
      <div class="section-heading" data-aos="fade-down">
        <h2>Why Choose Us</h2>
        <p>
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
        </p>
      </div>
      <!-- /Heading title -->
      <div class="why-choose-group">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12 d-flex" data-aos="fade-down">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="choose-img choose-black">
                  <img src="@/assets/img/icons/bx-user-check.svg" alt="" />
                </div>
                <div class="choose-content">
                  <h4>Easy & Fast Booking</h4>
                  <p>
                    Completely carinate e business testing process whereas fully
                    researched customer service. Globally extensive content with quality.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 d-flex" data-aos="fade-down">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="choose-img choose-secondary">
                  <img src="@/assets/img/icons/bx-user-check.svg" alt="" />
                </div>
                <div class="choose-content">
                  <h4>Many Pickup Location</h4>
                  <p>
                    Enthusiastically magnetic initiatives with cross-platform sources.
                    Dynamically target testing procedures through effective.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 d-flex" data-aos="fade-down">
            <div class="card flex-fill">
              <div class="card-body">
                <div class="choose-img choose-primary">
                  <img src="@/assets/img/icons/bx-user-check.svg" alt="" />
                </div>
                <div class="choose-content">
                  <h4>Customer Satisfaction</h4>
                  <p>
                    Globally user centric method interactive. Seamlessly revolutionize
                    unique portals corporate collaboration.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
