<template>
  <!-- services -->
  <section class="section services">
    <div class="service-right">
      <img
        src="@/assets/img/bg/service-right.svg"
        class="img-fluid"
        alt="services right"
      />
    </div>
    <div class="container">
      <!-- Heading title-->
      <div class="section-heading" data-aos="fade-down">
        <h2>How It Works</h2>
      </div>
      <!-- /Heading title -->
      <div class="services-work">
        <div class="row">
          <div
            class="col-lg-4 col-md-4 col-12"
            v-for="item in IndexService"
            :key="item.id"
            data-aos="fade-down"
          >
            <div class="services-group">
              <div class="services-icon border-secondary">
                <img
                  :class="item.ImgClass"
                  :src="require(`@/assets/img/icons/${item.Image}`)"
                  alt="Choose Locations"
                />
              </div>
              <div class="services-content">
                <h3>{{ item.Title }}</h3>
                <p>
                  {{item.Description}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /services -->
</template>

<script>
import IndexService from "@/assets/json/index-service.json";
export default {
  data() {
    return {
      IndexService: IndexService,
    };
  },
};
</script>
