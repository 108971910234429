<template>
  <layout-header></layout-header>
  <breadcrumb :title="title" :text="text" :text1="text1" />
<!--  <listing-head1></listing-head1>-->
  <section class="section product-details">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <listing-carphoto1></listing-carphoto1>

          <!--Listing Features Section-->
          <listing-specifications1></listing-specifications1>
          <listing-features1></listing-features1>
          <!--/Listing Features Section-->

          <listing-description1></listing-description1>

        </div>
        <listingdetails-sidebar1></listingdetails-sidebar1>
      </div>
    </div>
  </section>
  <layout-footer></layout-footer>
  <listing-modal></listing-modal>
</template>
<script>
export default {
  data() {
    return {
      title: "Valory 480",
      text: "Listings",
      text1: "Valory 480",
    };
  },
};
</script>
