<template>
  <div class="review-sec extra-service mb-0">
    <div class="review-header">
      <h4>Description</h4>
    </div>
    <div class="description-list">
      <p>
        Introducing the Valory 525 Premium, a game changer in premium boating services, designed to elevate your experience on the water to unprecedented levels of luxury and convenience.
        With its contemporary design and pre-made functions, the Valory 525 sets a new standard for excellence in boating.</p>
        <p>Imagine yourself cruising in style, effortlessly navigating the waterways with the central console that puts control at your fingertips.
        But the Valory 525 isn't just about looks,it's about enhancing your time on the water with innovative features and unparalleled comfort.</p>
          <p>The deck adds a touch of modern convenience, making docking and disembarking a breeze.
        Safety is paramount, and the Valory Valory 525 delivers a self-draining deck that ensures peace of mind during your adventures. Meanwhile, the optimized cockpit space and reclining seat in the stern provide the perfect spot to unwind and soak in the beauty of your surroundings.</p>
            <p>But that's not all our attention to detail is evident in every aspect of the Valory 525. Thoughtfully positioned handles and clever use of space ensure seamless transitions and maximum efficiency, allowing you to make the most of your time on the water.</p>
              <p> With the Valory 525, your boating experience reaches new heights of luxury, comfort, and convenience. Say goodbye to limitations and hello to endless possibilities—stay on the water all day and create memories that last a lifetime with the Valory V525.</p>
      <p>Book an 8-hour slot and enjoy complimentary transfer to and from the marina.  </p>
    </div>
  </div>

<!--  <div class="review-sec extra-service mb-0">-->
<!--    <div class="review-header">-->
<!--      <h4>Video</h4>-->
<!--    </div>-->
<!--    <div class="short-video">-->
<!--      <iframe-->
<!--        src="https://www.youtube.com/embed/ExJZAegsOis"-->
<!--        width="100"-->
<!--        height="350"-->
<!--      ></iframe>-->
<!--    </div>-->
<!--  </div>-->
</template>
<script setup lang="ts">
</script>