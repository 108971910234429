<template>
  <layout-header></layout-header>
  <breadcrumb :title="title" :text="text" :text1="text1" />
  <!-- About -->
  <aboutexperiences></aboutexperiences>
  <!-- /About -->

  <layout-footer></layout-footer>
</template>

<script>
export default {
  data() {
    return {
      title: "About us",
      text: "Pages",
      text1: "About us",
    };
  },
};
</script>
