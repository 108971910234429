<template>
  <div class="review-sec extra-service mb-0">
    <div class="review-header">
      <h4>Description</h4>
    </div>
    <div class="description-list">
      <p>
        Experience the magic of a Cretan sunset like never before. Embark on our enchanting Sunset Trip from 18:30 to 20:30 and witness the sky transform with breathtaking hues over the serene waters.
      </p>
      <p>
        Relax and unwind as our skilled skipper navigates the tranquil seas, ensuring a smooth and enjoyable journey. </p>
      <p>
        This 2-hour voyage includes delectable finger food and a selection drinks, and fuel,  all included in the price.
        </p>
      <p>
        Indulge in this magical evening and create unforgettable memories on the stunning coast of Kato Gouves.
      </p>
    </div>
  </div>

<!--  <div class="review-sec extra-service mb-0">-->
<!--    <div class="review-header">-->
<!--      <h4>Video</h4>-->
<!--    </div>-->
<!--&lt;!&ndash;    <div class="short-video">&ndash;&gt;-->
<!--&lt;!&ndash;      <iframe&ndash;&gt;-->
<!--&lt;!&ndash;          height="350"&ndash;&gt;-->
<!--&lt;!&ndash;          src="https://www.youtube.com/embed/ExJZAegsOis"&ndash;&gt;-->
<!--&lt;!&ndash;          width="100"&ndash;&gt;-->
<!--&lt;!&ndash;      ></iframe>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--  </div>-->
</template>
<script lang="ts" setup>
</script>