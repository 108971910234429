import { createApp } from 'vue'
import { router } from '@/router';
import App from './App.vue'
import {BootstrapVue3, BToastPlugin} from 'bootstrap-vue-3'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import VueSelect from 'vue3-select2-component'
import TimePicker from 'vue-timepicker';
import VueApexCharts from "vue3-apexcharts";
import DatePicker from 'vue3-datepicker'
import Vue3Autocounter from 'vue3-autocounter';


/********* components **********/
import Usermenu from '@/components/usermenu.vue'
import Breadcrumb from '@/components/breadcrumb.vue'
import UserBreadcrumb from '@/components/user-breadcrumb.vue'
import Calendar from '@/components/AKcalendar.vue'


/********* Layouts components **********/
import Header from '@/views/layouts/header.vue'
import Footer from '@/views/layouts/footer.vue'
import BlogSidebar from '@/views/layouts/blog-sidebar.vue'
import Scroll from '@/views/layouts/scroll.vue'
import SettingsSidebar from '@/views/layouts/settings-sidebar.vue'

/********* Pages components **********/
import IndexBanner from '@/views/pages/index/index-banner.vue'
import IndexService from '@/views/pages/index/index-service.vue'
import IndexPopular from '@/views/pages/index/index-popular.vue'
import Toyota from '@/views/pages/index/toyota.vue'
import IndexCartypes from '@/views/pages/index/index-cartypes.vue'
import IndexRental from '@/views/pages/index/index-rental.vue'
import IndexChoose from '@/views/pages/index/index-choose.vue'
import HomeFaq from '@/views/pages/index/home-faq.vue'
import HomePricing from '@/views/pages/index/home-pricing.vue'
import AboutExperiences from '@/views/pages/pages/about-us/aboutexperiences.vue'
import Service from '@/views/pages/pages/about-us/service.vue'
import Factnumbers from '@/views/pages/pages/about-us/factnumbers.vue'
import Testimonial from '@/views/pages/pages/about-us/testimonial.vue'
import Faq from '@/views/pages/pages/about-us/faq.vue'
import Choose_Us from '@/views/pages/pages/about-us/choose-us.vue'
import Listing_Filter from '@/views/pages/listing/listing-filter.vue'
import Listing_Sidebar from '@/views/pages/listing/listing-sidebar.vue'
import Listing_CarPhoto1 from '@/views/pages/listing/listing-details1/listing-carphoto1.vue'
import Listing_Specifications1 from '@/views/pages/listing/listing-details1/listing-specifications1.vue'
import Listing_Features1 from '@/views/pages/listing/listing-details1/listing-features1.vue'
import Listing_Description1 from '@/views/pages/listing/listing-details1/listing-description1.vue'
import Listingdetails_Sidebar1 from '@/views/pages/listing/listing-details1/listingdetails-sidebar1.vue'
import Listing1 from '@/views/pages/listing/listing-details1/listing1.vue'
import Listing_CarPhoto2 from '@/views/pages/listing/listing-details2/listing-carphoto2.vue'
import Listing_Specifications2 from '@/views/pages/listing/listing-details2/listing-specifications2.vue'
import Listing_Features2 from '@/views/pages/listing/listing-details2/listing-features2.vue'
import Listing_Description2 from '@/views/pages/listing/listing-details2/listing-description2.vue'
import Listingdetails_Sidebar2 from '@/views/pages/listing/listing-details2/listingdetails-sidebar2.vue'
import Listing2 from '@/views/pages/listing/listing-details2/listing2.vue'
import Listing_CarPhoto3 from '@/views/pages/listing/listing-details3/listing-carphoto3.vue'
import Listing_Specifications3 from '@/views/pages/listing/listing-details3/listing-specifications3.vue'
import Listing_Features3 from '@/views/pages/listing/listing-details3/listing-features3.vue'
import Listing_Description3 from '@/views/pages/listing/listing-details3/listing-description3.vue'
import Listingdetails_Sidebar3 from '@/views/pages/listing/listing-details3/listingdetails-sidebar3.vue'
import Listing3 from '@/views/pages/listing/listing-details3/listing3.vue'
import Listing_CarPhoto4 from '@/views/pages/listing/listing-details4/listing-carphoto4.vue'
import Listing_Description4 from '@/views/pages/listing/listing-details4/listing-description4.vue'
import Listingdetails_Sidebar4 from '@/views/pages/listing/listing-details4/listingdetails-sidebar4.vue'
import Listing4 from '@/views/pages/listing/listing-details4/listing4.vue'
import Sunset_CarPhoto from '@/views/pages/listing/sunset-details/sunset-carphoto.vue'
import Sunset_Description from '@/views/pages/listing/sunset-details/sunset-description.vue'
import Sunsetdetails_Sidebar from '@/views/pages/listing/sunset-details/sunsetdetails-sidebar.vue'
import Sunset from '@/views/pages/listing/sunset-details/sunset.vue'
import Sup_CarPhoto from '@/views/pages/listing/sup-details/sup-carphoto.vue'
import Sup_Description from '@/views/pages/listing/sup-details/sup-description.vue'
import Supdetails_Sidebar from '@/views/pages/listing/sup-details/supdetails-sidebar.vue'
import Sup from '@/views/pages/listing/sup-details/sup.vue'
import RefundPolicy from "@/views/pages/pages/refund-policy.vue";


/********* Modal components **********/



// plugins
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@/assets/css/feather.css';
import '@/assets/css/style.css';

  
const app = createApp(App)

/********* components **********/
app.component('usermenu', Usermenu)
app.component('breadcrumb', Breadcrumb)
app.component('user-breadcrumb', UserBreadcrumb)
app.component('AKcalendar', Calendar)

/********* Layouts components **********/
app.component('layout-header', Header)
app.component('layout-footer', Footer)
app.component('blog-sidebar', BlogSidebar)
app.component('scroll', Scroll)
app.component('settings-sidebar',SettingsSidebar)

/********* Pages components **********/
app.component('index-banner', IndexBanner)
app.component('index-service', IndexService)
app.component('index-popular', IndexPopular)
app.component('toyota', Toyota)
app.component('index-cartypes', IndexCartypes)
app.component('index-rental', IndexRental)
app.component('index-choose', IndexChoose)
app.component('home-faq', HomeFaq)
app.component('home-pricing', HomePricing)
app.component('aboutexperiences', AboutExperiences)
app.component('service', Service)
app.component('factnumbers', Factnumbers)
app.component('choose-us', Choose_Us)
app.component('testimonial', Testimonial)
app.component('refund-policy', RefundPolicy)
app.component('faq', Faq)
app.component('listing-filter',Listing_Filter)
app.component('listing-sidebar',Listing_Sidebar)
app.component('listing-carphoto1',Listing_CarPhoto1)
app.component('listing-specifications1',Listing_Specifications1)
app.component('listing-features1',Listing_Features1)
app.component('listing-description1',Listing_Description1)
app.component('listingdetails-sidebar1',Listingdetails_Sidebar1)
app.component('listing1',Listing1)
app.component('listing-carphoto2',Listing_CarPhoto2)
app.component('listing-specifications2',Listing_Specifications2)
app.component('listing-features2',Listing_Features2)
app.component('listing-description2',Listing_Description2)
app.component('listingdetails-sidebar2',Listingdetails_Sidebar2)
app.component('listing2',Listing2)
app.component('listing-carphoto3',Listing_CarPhoto3)
app.component('listing-specifications3',Listing_Specifications3)
app.component('listing-features3',Listing_Features3)
app.component('listing-description3',Listing_Description3)
app.component('listingdetails-sidebar3',Listingdetails_Sidebar3)
app.component('listing3',Listing3)
app.component('listing-carphoto4',Listing_CarPhoto4)
app.component('listing-description4',Listing_Description4)
app.component('listingdetails-sidebar4',Listingdetails_Sidebar4)
app.component('listing4',Listing4)
app.component('sunset-carphoto',Sunset_CarPhoto)
app.component('sunset-description',Sunset_Description)
app.component('sunsetdetails-sidebar',Sunsetdetails_Sidebar)
app.component('sunset',Sunset)
app.component('sup-carphoto',Sup_CarPhoto)
app.component('sup-description',Sup_Description)
app.component('supdetails-sidebar',Supdetails_Sidebar)
app.component('Sup1',Sup)


/********* Modal components **********/



app.component('vue3-autocounter', Vue3Autocounter)
app.component('vue-select', VueSelect)
app.component('datepicker', DatePicker)
app.component('timepicker', TimePicker)
app.use(VueApexCharts)
.use(BootstrapVue3)
.use(BToastPlugin)
.use(Antd)
app.use(router).mount('#app');
