<template>
  <div class="col-lg-3 col-12 theiaStickySidebar">
    <div class="stickysidebar">
      <form action="javascript:;" autocomplete="off" class="sidebar-form">
        <!-- Customer -->
        <div class="sidebar-heading">
          <h3>What Are You Looking For</h3>
        </div>
        <div class="product-search">
          <div class="form-custom">
            <input type="text" class="form-control" id="member_search1" placeholder="" />
            <span><img src="@/assets/img/icons/search.svg" alt="img" /></span>
          </div>
        </div>
        <div class="accordion" id="accordionMain1">
          <div class="card-header-new" id="headingOne">
            <h6 class="filter-title">
              <a
                href="javascript:void(0);"
                class="w-100"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Car Category
                <span class="float-end"><i class="fa-solid fa-chevron-down"></i></span>
              </a>
            </h6>
          </div>
          <div
            id="collapseOne"
            class="collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample1"
          >
            <div class="card-body-chat">
              <div class="row">
                <div class="col-md-12">
                  <div id="checkBoxes1">
                    <div class="selectBox-cont">
                      <label class="custom_check w-100">
                        <input type="checkbox" name="username" />
                        <span class="checkmark"></span> Tesla
                      </label>
                      <label class="custom_check w-100">
                        <input type="checkbox" name="username" />
                        <span class="checkmark"></span> Ford
                      </label>
                      <label class="custom_check w-100">
                        <input type="checkbox" name="username" />
                        <span class="checkmark"></span> Mercediz Benz
                      </label>
                      <label class="custom_check w-100">
                        <input type="checkbox" name="username" />
                        <span class="checkmark"></span> Audi
                      </label>
                      <!-- View All -->
                      <div class="view-content">
                        <div class="viewall-One">
                          <label class="custom_check w-100">
                            <input type="checkbox" name="username" />
                            <span class="checkmark"></span> Kia
                          </label>
                          <label class="custom_check w-100">
                            <input type="checkbox" name="username" />
                            <span class="checkmark"></span> Honda
                          </label>
                          <label class="custom_check w-100">
                            <input type="checkbox" name="username" />
                            <span class="checkmark"></span> Toyota
                          </label>
                        </div>
                      </div>
                      <!-- /View All -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Customer -->

        <div class="accordion" id="accordionMain2">
          <div class="card-header-new" id="headingTwo">
            <h6 class="filter-title">
              <a
                href="javascript:void(0);"
                class="w-100 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                Car Type
                <span class="float-end"><i class="fa-solid fa-chevron-down"></i></span>
              </a>
            </h6>
          </div>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample2"
          >
            <div class="card-body-chat">
              <div id="checkBoxes2">
                <div class="selectBox-cont">
                  <label class="custom_check w-100">
                    <input type="checkbox" name="username" />
                    <span class="checkmark"></span> Convertible
                  </label>
                  <label class="custom_check w-100">
                    <input type="checkbox" name="username" />
                    <span class="checkmark"></span> Crossover
                  </label>
                  <label class="custom_check w-100">
                    <input type="checkbox" name="username" />
                    <span class="checkmark"></span> Sedan
                  </label>
                  <label class="custom_check w-100">
                    <input type="checkbox" name="username" />
                    <span class="checkmark"></span> Wagon
                  </label>
                  <!-- View All -->
                  <div class="view-content">
                    <div class="viewall-One">
                      <label class="custom_check w-100">
                        <input type="checkbox" name="username" />
                        <span class="checkmark"></span> Pickup
                      </label>
                      <label class="custom_check w-100">
                        <input type="checkbox" name="username" />
                        <span class="checkmark"></span> Wagon
                      </label>
                      <label class="custom_check w-100">
                        <input type="checkbox" name="username" />
                        <span class="checkmark"></span> SUV
                      </label>
                    </div>
                  </div>
                  <!-- /View All -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- By Status -->
        <div class="accordion" id="accordionMain3">
          <div class="card-header-new" id="headingThree">
            <h6 class="filter-title">
              <a
                href="javascript:void(0);"
                class="w-100 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                Capacity
                <span class="float-end"><i class="fa-solid fa-chevron-down"></i></span>
              </a>
            </h6>
          </div>
          <div
            id="collapseThree"
            class="collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample3"
          >
            <div class="card-body-chat">
              <div id="checkBoxes3">
                <div class="selectBox-cont">
                  <label class="custom_check w-100">
                    <input type="checkbox" name="bystatus" />
                    <span class="checkmark"></span> 1-4
                  </label>
                  <label class="custom_check w-100">
                    <input type="checkbox" name="bystatus" />
                    <span class="checkmark"></span> 1-6
                  </label>
                  <label class="custom_check w-100">
                    <input type="checkbox" name="bystatus" />
                    <span class="checkmark"></span> 4-6
                  </label>
                  <label class="custom_check w-100">
                    <input type="checkbox" name="bystatus" />
                    <span class="checkmark"></span>4-8
                  </label>
                  <label class="custom_check w-100">
                    <input type="checkbox" name="bystatus" />
                    <span class="checkmark"></span>8+
                  </label>
                  <label class="custom_check w-100">
                    <input type="checkbox" name="bystatus" />
                    <span class="checkmark"></span> 10+
                  </label>
                  <label class="custom_check w-100">
                    <input type="checkbox" name="bystatus" />
                    <span class="checkmark"></span> 15+
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /By Status -->

        <!-- Category -->
        <div class="accordion" id="accordionMain4">
          <div class="card-header-new" id="headingFour">
            <h6 class="filter-title">
              <a
                href="javascript:void(0);"
                class="w-100 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="true"
                aria-controls="collapseFour"
              >
                Price
                <span class="float-end"><i class="fa-solid fa-chevron-down"></i></span>
              </a>
            </h6>
          </div>
          <div
            id="collapseFour"
            class="collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample4"
          >
            <div class="card-body-chat">
              <div class="filter-range">
                <Slider v-model="value" :merge="merge" />
              </div>
            </div>
          </div>
        </div>
        <!-- /Category -->

        <div class="accordion" id="accordionMain5">
          <div class="card-header-new" id="headingFive">
            <h6 class="filter-title">
              <a
                href="javascript:void(0);"
                class="w-100 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="true"
                aria-controls="collapseFive"
              >
                Rating
                <span class="float-end"><i class="fa-solid fa-chevron-down"></i></span>
              </a>
            </h6>
          </div>
          <div
            id="collapseFive"
            class="collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample5"
          >
            <div class="card-body-chat">
              <div id="checkBoxes4">
                <div class="selectBox-cont">
                  <label class="custom_check w-100">
                    <input type="checkbox" name="category" />
                    <span class="checkmark"></span>
                    <i class="fas fa-star filled me-1"></i>
                    <i class="fas fa-star filled me-1"></i>
                    <i class="fas fa-star filled me-1"></i>
                    <i class="fas fa-star filled me-1"></i>
                    <i class="fas fa-star filled"></i>
                  </label>
                  <label class="custom_check w-100">
                    <input type="checkbox" name="category" />
                    <span class="checkmark"></span>
                    <i class="fas fa-star filled me-1"></i>
                    <i class="fas fa-star filled me-1"></i>
                    <i class="fas fa-star filled me-1"></i>
                    <i class="fas fa-star filled"></i>
                  </label>
                  <label class="custom_check w-100">
                    <input type="checkbox" name="category" />
                    <span class="checkmark"></span>
                    <i class="fas fa-star filled me-1"></i>
                    <i class="fas fa-star filled me-1"></i>
                    <i class="fas fa-star filled"></i>
                  </label>
                  <label class="custom_check w-100">
                    <input type="checkbox" name="category" />
                    <span class="checkmark"></span>
                    <i class="fas fa-star filled me-1"></i>
                    <i class="fas fa-star filled"></i>
                  </label>
                  <div class="view-content">
                    <div class="viewall-Two">
                      <label class="custom_check w-100">
                        <input type="checkbox" name="username" />
                        <span class="checkmark"></span>
                        <i class="fas fa-star filled"></i>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion" id="accordionMain6">
          <div class="card-header-new" id="headingSix">
            <h6 class="filter-title">
              <a
                href="javascript:void(0);"
                class="w-100 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="true"
                aria-controls="collapseSix"
              >
                Customer Recommendation
                <span class="float-end"><i class="fa-solid fa-chevron-down"></i></span>
              </a>
            </h6>
          </div>
          <div
            id="collapseSix"
            class="collapse"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample6"
          >
            <div class="card-body-chat">
              <div id="checkBoxes5">
                <div class="selectBox-cont">
                  <label class="custom_check w-100">
                    <input type="checkbox" name="category" />
                    <span class="checkmark"></span> 70% & up
                  </label>
                  <label class="custom_check w-100">
                    <input type="checkbox" name="category" />
                    <span class="checkmark"></span> 60% & up
                  </label>
                  <label class="custom_check w-100">
                    <input type="checkbox" name="category" />
                    <span class="checkmark"></span> 50% & up
                  </label>
                  <label class="custom_check w-100">
                    <input type="checkbox" name="category" />
                    <span class="checkmark"></span> 40% & up
                  </label>
                  <div class="viewall-Two">
                    <label class="custom_check w-100">
                      <input type="checkbox" name="username" />
                      <span class="checkmark"></span>30% & up
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary filter-btn"
        >
          <span><i class="feather-filter me-2"></i></span>Filter results
        </button>
        <a href="javascript:;" class="reset-filter">Reset Filter</a>
      </form>
    </div>
  </div>
</template>
<script>
import Slider from "@vueform/slider";
export default {
  data() {
    return {
      value: [0, 50],
      merge: 10,
      format: {
        prefix: "",
      },
    };
  },
  components: {
    Slider,
  },
};
</script>
<style>
.slider-connect {
  background: var(--slider-connect-bg, #ff9307) !important;
  cursor: pointer;
}

.slider-tooltip {
  background: var(--slider-tooltip-bg, #0b2540) !important;
  border: 1px solid var(--slider-tooltip-bg, #0b2540) !important;
  border-radius: var(--slider-tooltip-radius, 5px);
  color: var(--slider-tooltip-color, #fff);
  display: block;
  font-size: var(--slider-tooltip-font-size, 0.875rem);
  font-weight: var(--slider-tooltip-font-weight, 600);
  line-height: var(--slider-tooltip-line-height, 1.25rem);
  min-width: var(--slider-tooltip-min-width, 20px);
  padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
  position: absolute;
  text-align: center;
  white-space: nowrap;
}

.roundskin .slider-tooltip {
  background: var(--slider-tooltip-bg, #ff9307) !important;
  border: 1px solid var(--slider-tooltip-bg, #ff9307) !important;
  border-radius: var(--slider-tooltip-radius, 5px);
  color: var(--slider-tooltip-color, #fff);
  display: block;
  font-size: var(--slider-tooltip-font-size, 0.875rem);
  font-weight: var(--slider-tooltip-font-weight, 600);
  line-height: var(--slider-tooltip-line-height, 1.25rem);
  min-width: var(--slider-tooltip-min-width, 20px);
  padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
  position: absolute;
  text-align: center;
  white-space: nowrap;
}

.slider-handle {
  background-color: #ff9307 !important;
  border: 2px solid #ff9307 !important;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
