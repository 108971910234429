<template>
  <layout-header></layout-header>
  <breadcrumb :title="title" :text="text" :text1="text1" />
<!--  <listing-head3></listing-head3>-->
  <section class="section product-details">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <listing-carphoto3></listing-carphoto3>

          <!--Listing Features Section-->
          <listing-specifications3></listing-specifications3>
          <listing-features3></listing-features3>
          <!--/Listing Features Section-->

          <listing-description3></listing-description3>

<!--          <listing-review3></listing-review3>-->
        </div>
        <listingdetails-sidebar3></listingdetails-sidebar3>
      </div>

    </div>
  </section>
  <layout-footer></layout-footer>
  <listing-modal></listing-modal>
</template>
<script>
export default {
  data() {
    return {
      title: "Cobra 495",
      text: "Listings",
      text1: "Cobra 495",
    };
  },
};
</script>
