<template>
  <!-- Rental deals -->
  <section class="section popular-services">
    <div class="container">
      <!-- Heading title-->
      <div class="section-heading" data-aos="fade-down">
        <h2>Our Fleet</h2>

      </div>
      <!-- /Heading title -->
      <div class="row">
        <div class="popular-slider-group">
          <div class="owl-carousel rental-deal-slider owl-theme">
            <!-- owl carousel item -->
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in Rental" :key="item.id">
                <div class="rental-car-item">
                  <div class="listing-item mb-0">
                    <div class="listing-img">
                      <router-link :to="(`/listing/listing-details${item.id}`)" >
                        <img
                          :src="require(`@/assets/img/cars/${item.Image}`)"
                          class="img-fluid"
                          alt="Toyota"
                        />
                      </router-link>
                    </div>
                    <div class="listing-content">
                      <div class="listing-features">
                        <div class="fav-item-rental">
                          <span class="featured-text">{{ item.Badge }}</span>
                        </div>

                        <h3 class="listing-title">
                          <router-link :to="(`/listing/listing-details${item.id}`)" >{{ item.Title }}</router-link>
                        </h3>
                      </div>
                      <div class="listing-details-group">
                        <ul>
                          <li>
                            <span
                              ><img src="@/assets/img/icons/wifi.svg" alt="Auto"
                            /></span>
                            <p>WiFi</p>
                          </li>
                          <li>
                            <span
                              ><img src="@/assets/img/icons/car-parts-02.svg" alt="10 KM"
                            /></span>
                            <p>30hp</p>
                          </li>
                          <li>
                            <span
                              ><img
                                src="@/assets/img/icons/car-parts-03.svg"
                                alt="Petrol"
                            /></span>
                            <p>Petrol</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span
                              ><img src="@/assets/img/icons/gps.svg" alt="Power"
                            /></span>
                            <p>GPS</p>
                          </li>
                          <li>
                            <span
                              ><img src="@/assets/img/icons/car-parts-05.svg" alt="2023"
                            /></span>
                            <p>2024</p>
                          </li>
                          <li>
                            <span
                              ><img
                                src="@/assets/img/icons/car-parts-06.svg"
                                alt="Persons"
                            /></span>
                            <p>{{item.Capacity}} persons</p>
                          </li>
                        </ul>
                      </div>
                      <div class="listing-button">
                        <router-link :to="(`/listing/listing-details${item.id}`)" class="btn btn-order"
                        ><span><i class="feather-calendar me-2"></i></span>Rent
                          Now</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
            <!-- /owl carousel item -->
          </div>
        </div>
      </div>
      <!-- View More -->
   <!--   <div class="view-all text-center" data-aos="fade-down">
        <router-link
          to="/listing/listing-grid"
          class="btn btn-view d-inline-flex align-items-center"
          >Go to all Cars <span><i class="feather-arrow-right ms-2"></i></span
        ></router-link>
      </div> -->
      <!-- View More -->
    </div>
  </section>
  <!-- /Rental deals -->
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import Rental from "@/assets/json/rental.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      Rental: Rental,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Navigation,
  },
};
</script>
