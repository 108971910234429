<template>
  <section class="section about-sec">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6" data-aos="fade-down">
          <div class="about-img">
            <div class="about-exp">
            </div>
            <div class="abt-img">
              <img src="@/assets/img/new_logo.png" class="img-fluid" alt="About us" />
            </div>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-down">
          <div class="about-content">
            <h6>About Black Anchor</h6>
            <h2>Your License-Free Boating Solution</h2>
            <p>Welcome to Black Anchor, where we make it effortless for you to experience the joys of boating without the hassle of obtaining a boat license.</p>

            <p>At Black Anchor, we're passionate about providing unforgettable moments on the water for everyone, whether you're a seasoned sailor or a first-time adventurer. Our mission is to make boating accessible to all, offering a safe and enjoyable experience that allows you to explore the beauty of our waterways at your own pace.</p>

            <p>With a fleet of well-maintained and fully-equipped boats, we offer a range of options to suit your needs and preferences. Whether you're looking for a relaxing day of fishing, a thrilling speedboat ride, or a leisurely cruise with friends and family, we have the perfect vessel for you.</p>

            <p>But it's not just about the boats – it's about the service. Our team is dedicated to ensuring that your experience with us is nothing short of exceptional. From assisting you with selecting the right boat to providing safety instructions and insider tips on the best spots to explore, we're here to make sure your time on the water is both enjoyable and worry-free.</p>

            <p>So whether you're a local resident looking for a weekend adventure or a visitor eager to discover the beauty of Crete's waterways, come join us at Black Anchor for an unforgettable boating experience.</p>

            <p>   We can't wait to welcome you on board! </p>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
</script>