<template>
  <layout-header></layout-header>
  <breadcrumb :title="title" :text="text" :text1="text1" />
<!--  <listing-head3></listing-head3>-->
  <section class="section product-details">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <sunset-carphoto></sunset-carphoto>

          <!--Listing Features Section-->
<!--          <listing-specifications4></listing-specifications4>-->
<!--          <listing-features4></listing-features4>-->
          <!--/Listing Features Section-->

          <sunset-description></sunset-description>

<!--          <listing-review3></listing-review3>-->
        </div>
        <sunsetdetails-sidebar></sunsetdetails-sidebar>
      </div>

    </div>
  </section>
  <layout-footer></layout-footer>
  <listing-modal></listing-modal>
</template>
<script>

export default {

  data() {
    return {
      title: "Sunset Trip",
      text: "Listings",
      text1: "Sunset Trip",
    };
  },
};
</script>
