import {createRouter, createWebHistory} from 'vue-router';

import Index from '@/views/pages/index/index.vue'
import Vue from 'vue';
import Router from 'vue-router';
import Faq from '@/views/pages/pages/faq.vue'
import Coming_Soon from '@/views/pages/pages/coming-soon.vue'
import Maintenance from '@/views/pages/pages/maintenance.vue'
import Terms_Condition from '@/views/pages/pages/terms-condition.vue'
import Our_Team from '@/views/pages/pages/our-team.vue'
import Gallery from '@/views/pages/pages/gallery.vue'
import Pricing from '@/views/pages/pages/pricing.vue'
import Error_404 from '@/views/pages/pages/error/error-404.vue'
import Error_500 from '@/views/pages/pages/error/error-500.vue'
import Booking_Payment from '@/views/pages/pages/booking/booking-payment.vue'
import BookingContent from '@/views/pages/pages/booking/booking-content.vue'
import Invoice_Details from '@/views/pages/pages/booking/invoice-details.vue'
import About_Us from '@/views/pages/pages/about-us/about-us.vue'
import ContactUs from '@/views/pages/contact-us.vue'
import Listing_Grid from '@/views/pages/listing/listing-grid/listing-grid.vue'
import Listing_Details1 from '@/views/pages/listing/listing-details1/listing-details1.vue'
import Listing_Details2 from '@/views/pages/listing/listing-details2/listing-details2.vue'
import Listing_Details3 from '@/views/pages/listing/listing-details3/listing-details3.vue'
import Listing_Details4 from '@/views/pages/listing/listing-details4/listing-details4.vue'
import Sunset_Details from '@/views/pages/listing/sunset-details/sunset-details.vue'
import Sup_Details from '@/views/pages/listing/sup-details/sup-details.vue'
import Listing from '@/views/pages/listing/listing.vue'
import Pages from '@/views/pages/pages/pages.vue'
import Error from '@/views/pages/pages/error/error.vue'
import refundPolicy from "@/views/pages/pages/refund-policy.vue";



const routes = [
  {
    path: '/',
    redirect: 'index',
  },
  {
    path: '/index',
    name: 'index',
    component: Index
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: ContactUs
  },
 {
  path: '/listing',
  component: Listing,
  children: [
    { path: '', redirect: '/listing/listing-grid' },
    { path: "listing-grid", component: Listing_Grid },
    { path: "listing-details1", component: Listing_Details1 },
    { path: "listing-details2", component: Listing_Details2 },
    { path: "listing-details3", component: Listing_Details3 },
    { path: "listing-details4", component: Listing_Details4 },
  ]
},
  {
    path: '/listing/sunset-details',
    name: 'sunset',
    component: Sunset_Details,
  },
  {
    path: '/listing/sup-details',
    name: 'sup',
    component: Sup_Details,
  },
{
  path: '/pages',
  component: Pages,
  children: [
    { path: '', redirect: '/pages/about-us' },
    { path: "about-us", component: About_Us },
    { path: "pricing", component: Pricing },
    { path: "faq", component: Faq },
    { path: "gallery", component: Gallery },
    { path: "our-team", component: Our_Team },
    { path: "terms-condition", component: Terms_Condition },
    {path: "refund-policy", component: refundPolicy},
    { path: "maintenance", component: Maintenance },
    { path: "coming-soon", component: Coming_Soon },
    { path: "booking-payment", component: Booking_Payment },
    { path: "Booking", component: BookingContent },
    { path: "invoice-details", component: Invoice_Details},


  ]
},

{
  path: '/error',
  component: Error,
  children: [
    { path: '', redirect: '/error/error-404' },
    { path: "error-404", component: Error_404 },
    { path: "error-500", component: Error_500 }
  ]
},

];


export const router = createRouter({
    history: createWebHistory('/'),
    linkActiveClass: 'active',
    routes
});

router.beforeEach((to, from, next) => {
  // Scroll to the top of the page
  window.scrollTo({ top: 0, behavior: 'smooth' });

  // Continue with the navigation
  next();
});

