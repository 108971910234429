<template>
  <div class="review-sec extra-service mb-0">
    <div class="review-header">
      <h4>Description</h4>
    </div>
    <div class="description-list">
      <p>
        Prepare to be amazed by the pinnacle of aquatic luxury: the Isatek Tekne '24 COBRA 495! Step aboard and immerse yourself in a world where elegance meets exhilaration.
        Picture yourself gliding effortlessly across the shimmering waves, embraced by the sleek design and cutting-edge features of this masterpiece.</p>
        <p>With its spacious deck and comfortable seating, the COBRA 495 is more than just a boat—it's a sanctuary of relaxation and adventure.
        Whether you're seeking heart-pounding thrills or serene moments of tranquility, this vessel caters to your every desire. From adrenaline-fueled water sports to leisurely coastal cruises, the COBRA 495 delivers an experience like no other.</p>
          <p>But it's not just about the journey—it's about the memories you create along the way. With friends and family by your side, every moment aboard the COBRA 495 becomes a cherished story waiting to be told.
        So why settle for the ordinary when you can experience the extraordinary? Book your voyage on the Isatek Tekne '24 COBRA 495 today and elevate your aquatic escapades to new heights. Your adventure begins here.      </p>
      <p>Book an 8-hour slot and enjoy complimentary transfer to and from the marina.   </p>
    </div>
  </div>

<!--  <div class="review-sec extra-service mb-0">-->
<!--    <div class="review-header">-->
<!--      <h4>Video</h4>-->
<!--    </div>-->
<!--    <div class="short-video">-->
<!--      <iframe-->
<!--        src="https://www.youtube.com/embed/ExJZAegsOis"-->
<!--        width="100"-->
<!--        height="350"-->
<!--      ></iframe>-->
<!--    </div>-->
<!--  </div>-->
</template>
<script setup lang="ts">
</script>