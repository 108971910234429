<template>
  <div class="error-box">
    <img src="@/assets/img/404.png" class="img-fluid" alt="Page not found" />
    <h3>Oops! Page not found!</h3>
    <p>The page you requested was not found.</p>
    <div class="back-button">
      <router-link to="/index" class="btn btn-primary">Back to Home</router-link>
    </div>
  </div>
</template>
