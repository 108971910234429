<!-- App.vue -->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  watch: {
    '$route' (to, from) {
      // This function runs every time the route changes
      this.runScript();
    }
  },
  methods: {
    runScript() {
      // Remove the old script if it exists
      const oldScript = document.getElementById('lead-catcher');
      if (oldScript) {
        oldScript.parentNode.removeChild(oldScript);
      }

      // Append the new script
      const script = document.createElement('script');
      script.id = 'lead-catcher';
      script.src = "https://theleadcatcher.com/664f9f32742be8e5c50deef8/script.js";
      script.type = "text/javascript";
      document.body.appendChild(script);
    }
  },
  created() {
    // Run the script on the initial load
    this.runScript();
  }
}
</script>