<template>
  <div class="review-sec extra-service mb-0">
    <div style="max-width: 800px; margin: auto; background: #fff; padding: 20px; border-radius: 8px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);">
      <h3 style="color: #333;">Rent Paddle Boards (SUPs) with Us!</h3>
      <p style="color: #555;">Discover the beauty of the coastline with our high-quality stand-up paddle boards (SUPs). Whether you’re a seasoned paddler or a first-time adventurer, our paddle boards offer a fun and relaxing way to explore the water.</p>

      <h4 style="color: #444;">Why Choose Our Paddle Boards?</h4>
      <ul style="list-style: none; padding: 0;">
        <li style=" margin: 5px 0; padding: 10px; border-radius: 4px;"><strong>Top-Quality Equipment:</strong> We offer premium paddle boards that are stable, durable, and easy to handle, ensuring you have a safe and enjoyable experience on the water.</li>
        <li style=" margin: 5px 0; padding: 10px; border-radius: 4px;"><strong>For Everyone:</strong> Our SUPs are suitable for all skill levels, from beginners to experienced paddlers.</li>
        <li style=" margin: 5px 0; padding: 10px; border-radius: 4px;"><strong>Beautiful Locations:</strong> Paddle along the stunning coastline, explore hidden coves, or simply enjoy a peaceful ride on the open water.</li>
        <li style=" margin: 5px 0; padding: 10px; border-radius: 4px;"><strong>Flexible Rentals:</strong> Choose from hourly, half-day, or full-day rentals to fit your schedule and plans.</li>
        <li style=" margin: 5px 0; padding: 10px; border-radius: 4px;"><strong>Affordable Prices:</strong> Enjoy competitive rates and great value for your adventure.</li>
      </ul>


      <h4 style="color: #444;">What’s Included:</h4>
      <ul style="list-style: none; padding: 0;">
        <li style="margin: 5px 0; padding: 10px; border-radius: 4px;">High-quality paddle board and paddle</li>
        <li style=" margin: 5px 0; padding: 10px; border-radius: 4px;">Life jacket for safety</li>
        <li style=" margin: 5px 0; padding: 10px; border-radius: 4px;">Basic instructions for beginners</li>
        <li style=" margin: 5px 0; padding: 10px; border-radius: 4px;">Optional dry bag for your belongings</li>
      </ul>

      <h4 style="color: #444;">Book Your Paddle Board Today!</h4>
      <p style="color: #555;">Ready to hit the water? Contact us now to reserve your paddle board. We look forward to helping you create unforgettable memories on the water.</p>
    </div>
  </div>

<!--  <div class="review-sec extra-service mb-0">-->
<!--    <div class="review-header">-->
<!--      <h4>Video</h4>-->
<!--    </div>-->
<!--&lt;!&ndash;    <div class="short-video">&ndash;&gt;-->
<!--&lt;!&ndash;      <iframe&ndash;&gt;-->
<!--&lt;!&ndash;          height="350"&ndash;&gt;-->
<!--&lt;!&ndash;          src="https://www.youtube.com/embed/ExJZAegsOis"&ndash;&gt;-->
<!--&lt;!&ndash;          width="100"&ndash;&gt;-->
<!--&lt;!&ndash;      ></iframe>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--  </div>-->
</template>
<script lang="ts" setup>
</script>