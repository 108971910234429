<template>
  <layout-header></layout-header>
  <breadcrumb :title="title" :text="text" :text1="text1" />
  <listing-head2></listing-head2>
  <section class="section product-details">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <listing-carphoto2></listing-carphoto2>

          <!--Listing Features Section-->
          <listing-specifications2></listing-specifications2>
          <listing-features2></listing-features2>
          <!--/Listing Features Section-->

          <listing-description2></listing-description2>

        </div>
        <listingdetails-sidebar2></listingdetails-sidebar2>
      </div>
    </div>
  </section>
  <layout-footer></layout-footer>
  <listing-modal></listing-modal>
</template>
<script>
export default {
  data() {
    return {
      title: "Valory 525",
      text: "Listings",
      text1: "Valory 525",
    };
  },
};
</script>
