<template>
  <layout-header></layout-header>
  <breadcrumb :title="title" :text="text" :text1="text1" />
  <section class="section privacy-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="terms-policy">
            <h3>Refund Policy</h3>
            <p>At [Your Business Name], we strive to ensure that our customers have a safe and enjoyable experience. We understand that unforeseen circumstances can occur, and we have established the following refund policy:</p>

            <h4>Cancellation by Customer:</h4>
            <ul>
              <li>30% Cancellation Fee: For bookings cancelled within a period from booking to 60 days prior to boat embarkation, a cancellation fee of 30% of the total charter fee will be applied.</li>
              <li>50% Cancellation Fee: For bookings cancelled within a period of 59 to 50 days prior to boat embarkation, a cancellation fee of 50% of the total charter fee will be applied.</li>
              <li>80% Cancellation Fee: For bookings cancelled within a period of 49 to 30 days prior to boat embarkation, a cancellation fee of 80% of the total charter fee will be applied.</li>
              <li>100% Cancellation Fee: For bookings cancelled within a period of 30 days prior to boat embarkation, a cancellation fee of 100% of the total charter fee will be applied.</li>
            </ul>

            <h4>Cancellation by Us:</h4>
            <p>If we need to cancel your reservation due to unforeseen circumstances on our end, you will receive a full refund or the option to reschedule.</p>

            <h4>Bad Weather Policy:</h4>
            <p>In case of bad weather, we prioritize your safety. If the weather conditions are deemed unsafe for boating, we can arrange for your trip to be rescheduled to a different day. If rescheduling is not possible, you will receive a full refund.</p>

            <h4>No-Shows:</h4>
            <p>If you do not show up for your reservation without prior notice, no refund will be provided.</p>

            <h4>Other Considerations:</h4>
            <ul>
              <li>Any requests for refunds must be made in writing and sent to blackanchor.boats@gmail.com .</li>
              <li>Refunds will be processed within 5 business days of the request.</li>
            </ul>

            <p>We appreciate your understanding and cooperation. If you have any questions or need further assistance, please feel free to contact us at blackanchor.boats@gmail.com.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <layout-footer></layout-footer>
</template>

<script>
export default {
  data() {
    return {
      title: "Privacy Policy",
      text: "Pages",
      text1: "Privacy Policy",
    };
  },
};
</script>
