<template>
  <div class="review-sec extra-service mb-0">
    <div class="review-header">
      <h4>Description </h4>
    </div>
    <div class="description-list">
      <p>
        Experience the epitome of luxury and versatility with the VALORY 480 a timeless classic reimagined for the modern adventurer. </p>
      <p>With its spacious and comfortable interior, equipped with a centre console and exquisite upholstery as standard, the VALORY 480 promises an unparalleled boating experience.</p>
        <p>But that's just the beginning. We've taken the VALORY  480 to new heights with a range of exciting extras, including a Bimini top for shaded relaxation, a convenient bathing ladder for easy access to the water, and an automatic bilge pump for added safety and peace of mind.</p>
       <p> And let's not forget about the details that make all the difference—a cushioned interior for maximum comfort, a cushion with a sundeck for ultimate relaxation, and cupholders strategically placed for your convenience.</p>
        <p>With its classic hull design boasting large, wide, and high sides, the VALORY 480 offers stability and safety.. And the charming rope decoration adds a touch of elegance and sophistication to its timeless style.
        So why wait? </p>
      <p>Book an 8-hour slot and enjoy complimentary transfer to and from the marina. </p>

      <p>Elevate your boating experience with the VALORY 480 and discover the perfect blend of luxury, comfort, and performance. Your next unforgettable adventure awaits!
      </p>
    </div>
  </div>

<!--  <div class="review-sec extra-service mb-0">-->
<!--    <div class="review-header">-->
<!--      <h4>Video</h4>-->
<!--    </div>-->
<!--    <div class="short-video">-->
<!--      <iframe-->
<!--        src="https://www.youtube.com/embed/ExJZAegsOis"-->
<!--        width="100"-->
<!--        height="350"-->
<!--      ></iframe>-->
<!--    </div>-->
<!--  </div>-->
</template>
