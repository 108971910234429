
       <template>
         <div>
           <!-- Banner -->
           <div id="hero">

             <video autoplay loop muted>
               <source src="@/assets/img/video3.mp4" type="video/mp4">
             </video>

             <div class="content">
               <div class="container">
                 <div class="col-lg-4" data-aos="fade-down">

                   <h1><span>Be a Captain for a Day!</span></h1>

                   <h3><span style="color: #FFFFFF">No licence required.</span></h3>

                   <div class="view-all">
                     <router-link
                         to="/listing/listing-grid"
                       class="btn btn-xl d-inline-flex align-items-center '"
                         style="color: #FFFFFF; border: 1px solid #FFFFFF; border-radius: 5px; padding: 10px 20px; margin-top: 20px;"
                     >Book Now <span><i class="feather-arrow-right ms-2"></i></span
                     ></router-link>
                   </div>
                 </div>
               </div>
             </div>

           </div>


           <!-- Search
    <div class="section-search">
      <div class="container">
        <div class="search-box-banner">
          <form @submit.prevent="submitForm">
            <ul class="align-items-center">
              <li class="column-group-main">
                <div class="input-block">
                  <label>Pickup Location</label>
                  <div class="input-block-wrapp">

                      <select  >
                        <option selected>Choose...</option>
                        <option value="1">Valori 525</option>
                        <option value="2">Valori 480</option>
                        <option value="3">Cobra 495</option>
                      </select>

                  </div>
                </div>
              </li>
              <li class="column-group-main">
                <div class="input-block">
                  <label>Pickup Date</label>
                </div>
                <div class="input-block-wrapp">
                  <div class="input-block date-widget">
                    <div class="group-img">
                      <datepicker
                          v-model="startdate"
                          class="form-control datetimepicker"
                          :editable="true"
                          :clearable="false"
                          :input-format="dateFormat"
                          placeholder="04/11/2023"
                      />
                      <span><i class="feather-calendar"></i></span>
                    </div>
                  </div>

                </div>
              </li>
              <li class="column-group-main">
                <div class="input-block">
                  <label>Pickup Time</label>
                </div>
                <div class="input-block time-widge">
                  <div class="group-img">
                    <a-time-picker
                        class="form-control timepicker"
                        placeholder="11:00 AM"
                        use12-hours
                        format="h:mm a"
                        type="text"
                    />
                    <span><i class="feather-clock"></i></span>
                  </div>
                </div>
              </li>
              <li class="column-group-main">
                <div class="input-block">
                  <label>Return Time</label>
                </div>
                <div class="input-block-wrapp">

                  <div class="input-block time-widge">
                    <div class="group-img">
                      <a-time-picker
                          class="form-control timepicker"
                          placeholder="11:00 AM"
                          use12-hours
                          format="h:mm a"
                      />
                      <span><i class="feather-clock"></i></span>
                    </div>
                  </div>
                </div>
              </li>
              <li class="column-group-last">
                <div class="input-block">
                  <div class="search-btn">
                    <button class="btn search-button" type="submit">
                      <i class="fa fa-search" aria-hidden="true"></i>Search
                    </button>
                  </div>
                </div>
              </li>

            </ul>
          </form>
        </div>
      </div>
    </div>-->
    </div>
    <!-- /Search -->
  </template>

  <script>
    // import { ref } from "vue";
    // const value = ref();
    // const currentDate = ref(new Date());
    // const currentDateOne = ref(new Date());
    // export default {
    //   data() {
    //     return {
    //       startdate: currentDate,
    //       startdateOne: currentDateOne,
    //       dateFormat: "dd-MM-yyyy",
    //       value,
    //     };
    //   },
    //   setup() {
    //     const value = ref();
    //     return { value };
    //   },
    //   methods: {
    //     submitForm() {
    //       this.$router.push("/listing/listing-grid");
    //     },
    //   },
    // };
  </script>