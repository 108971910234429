<template>
    <!-- FAQ  -->
    <section class="section faq-section bg-light-primary">
        <div class="container">
            <!-- Heading title-->
            <div class="section-heading" data-aos="fade-down">
                <h2>Frequently Asked Questions </h2>
            </div>
            <!-- /Heading title -->
            <div class="faq-info">
                <div class="faq-card bg-white" data-aos="fade-down">
                      <div class="faq-card bg-white" data-aos="fade-down">
                        <h4 class="faq-title">
                          <a class="collapsed" data-bs-toggle="collapse" href="#faqOne" aria-expanded="false">Is fuel included in the price?</a>
                        </h4>
                        <div id="faqOne" class="card-collapse collapse">
                          <p>Fuel is not included in the rental price. However, we only charge you for the fuel you use during your rental period. Our team will assist you in estimating your fuel needs. This way, you have full control over your expenses and can enjoy your boating experience without any surprises</p>
                        </div>
                      </div>
                      <div class="faq-card bg-white" data-aos="fade-down">
                        <h4 class="faq-title">
                          <a class="collapsed" data-bs-toggle="collapse" href="#faqTwo" aria-expanded="false">Is there any training for proper boat handling?</a>
                        </h4>
                        <div id="faqTwo" class="card-collapse collapse">
                          <p>Absolutely! We provide comprehensive training sessions for proper boat handling before your rental. Our experienced staff will guide you through everything you need to know, from basic operations to safety procedures. Whether you're a beginner or an experienced boater, we ensure you feel confident and comfortable navigating the water. Your safety and enjoyment are our top priorities, and we're here to support you every step of the way.</p>
                        </div>
                      </div>
                      <div class="faq-card bg-white" data-aos="fade-down">
                        <h4 class="faq-title">
                          <a class="collapsed" data-bs-toggle="collapse" href="#faqThree" aria-expanded="false">Do I need a license to sail the boat?</a>
                        </h4>
                        <div id="faqThree" class="card-collapse collapse">
                          <p>Our boats are designed for ease of use and enjoyment on the water, making them accessible to all levels of boaters. Rest assured, our team will provide you with all the necessary instructions and safety guidelines before you embark on your adventure. </p>
                        </div>
                      </div>
                      <div class="faq-card bg-white" data-aos="fade-down">
                        <h4 class="faq-title">
                          <a class="collapsed" data-bs-toggle="collapse" href="#faqFour" aria-expanded="false">Is a boat trip safe?</a>
                        </h4>
                        <div id="faqFour" class="card-collapse collapse">
                          <p>Safety is our top priority at Black Anchor. Our rented boats undergo regular maintenance checks and are equipped with all the necessary safety features to ensure a secure and enjoyable experience for our customers. Additionally, our team provides comprehensive safety instructions before every rental, including guidance on boat operation, emergency procedures, and adherence to local regulations. We also provide life jackets for all passengers and encourage responsible boating practices.
                            We continuously monitor weather forecasts and water conditions to ensure safe boating conditions at all times.
                            Furthermore, we understand that safety is paramount, and we are committed to providing a worry-free experience for all our customers. If you have any specific concerns or questions regarding safety, please don't hesitate to let us know. We're here to ensure you have a fantastic and safe boating adventure with us.</p>
                        </div>
                      </div>
                      <div class="faq-card bg-white" data-aos="fade-down">
                        <h4 class="faq-title">
                          <a class="collapsed" data-bs-toggle="collapse" href="#faqFive" aria-expanded="false">I don't know how to swim, can I get on the boat?</a>
                        </h4>
                        <div id="faqFive" class="card-collapse collapse">
                          <p>Of course! While swimming skills are beneficial, they're not a requirement for enjoying our rented boats. Just make sure to wear your life jacket. We want you to feel comfortable and secure during your time on the water. If you have any concerns or special requests, please let us know, and we'll be happy to accommodate you.</p>
                        </div>
                      </div>
                      <div class="faq-card bg-white" data-aos="fade-down">
                        <h4 class="faq-title">
                          <a class="collapsed" data-bs-toggle="collapse" href="#faqSix" aria-expanded="false">Can children get on the boat?</a>
                        </h4>
                        <div id="faqSix" class="card-collapse collapse">
                          <p>Yes, We prioritize safety for all passengers, and our boats are equipped to accommodate families with children. We provide life jackets specifically designed for children. If you have any specific concerns or requirements regarding children on the boat, please let us know, and we'll be happy to assist you.</p>
                        </div>
                      </div>
                      <div class="faq-card bg-white" data-aos="fade-down">
                        <h4 class="faq-title">
                          <a class="collapsed" data-bs-toggle="collapse" href="#faqSeven" aria-expanded="false">Can we get lost?</a>
                        </h4>
                        <div id="faqSeven" class="card-collapse collapse">
                          <p>Our boats are fitted with GPS devices and we provide mobile phones for communication, ensuring you always have a way to navigate and reach out for assistance if needed. Additionally, our staff provides thorough instructions and guidance before your departure to help you navigate the waterways safely. We're committed to ensuring your peace of mind and a worry-free experience on the water.</p>
                        </div>
                      </div>
                      <div class="faq-card bg-white" data-aos="fade-down">
                        <h4 class="faq-title">
                          <a class="collapsed" data-bs-toggle="collapse" href="#faqEight" aria-expanded="false">In case of adverse weather conditions, what happens with boat rentals?</a>
                        </h4>
                        <div id="faqEight" class="card-collapse collapse">
                          <p>In the event of adverse weather conditions, your safety is our top priority. If conditions pose a risk to your safety or enjoyment, we may need to reschedule your rental for another time or offer a refund. Our team closely monitors weather forecasts and will provide timely updates and assistance to ensure your safety and satisfaction. Your understanding and cooperation are greatly appreciated, as we prioritize your well-being on the water. </p>
                </div>
              </div>
             </div>
            </div>
        </div>
    </section>
    <!-- /FAQ -->
</template>
<script setup>
</script>