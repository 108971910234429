<template>
  <div class="review-sec extra-service mb-0">
    <div class="review-header">
      <h4>Description</h4>
    </div>
    <div class="description-list">
      <p>
        Experience the ultimate adventure on the water with our exclusive Combo Boat Rental Offer! Choose any two boats
        from our diverse fleet and enjoy a special discounted price. Whether you're planning a fun family outing or an
        exciting day with friends, our combo offer gives you the flexibility and value you need.
      </p>
      <p>
        Choose any 2 boats: Pick the perfect pair from our wide selection. </p>
      <p>
         Better Price: Enjoy a special discount when you rent two boats together. </p>
      <p>
         Flexible Options: Mix and match different types of boats to suit your needs.
        </p>
      <p>
        Book now and take advantage of this fantastic offer to make the most of your time on the water!
      </p>
    </div>
  </div>

<!--  <div class="review-sec extra-service mb-0">-->
<!--    <div class="review-header">-->
<!--      <h4>Video</h4>-->
<!--    </div>-->
<!--&lt;!&ndash;    <div class="short-video">&ndash;&gt;-->
<!--&lt;!&ndash;      <iframe&ndash;&gt;-->
<!--&lt;!&ndash;          height="350"&ndash;&gt;-->
<!--&lt;!&ndash;          src="https://www.youtube.com/embed/ExJZAegsOis"&ndash;&gt;-->
<!--&lt;!&ndash;          width="100"&ndash;&gt;-->
<!--&lt;!&ndash;      ></iframe>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--  </div>-->
</template>
<script lang="ts" setup>
</script>