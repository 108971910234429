<template>
  <div class="review-sec listing-feature">
    <div class="review-header">
      <h4>Boat Features</h4>
    </div>
    <div class="listing-description">
      <div class="row">
        <div class="col-md-4" v-for="item in Listing_Features" :key="item.id">
          <ul>
            <li>
              <span><i class="fa-solid fa-check-double"></i></span>{{ item.feature }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Listing_Features from "@/assets/json/listing-features3.json";
export default {
  data() {
    return {
      Listing_Features: Listing_Features,
    };
  },
};
</script>
