<template>
  <!-- Header -->
  <header class="header">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">

          <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>

          <router-link to="/index" class="navbar-brand logo">
            <img src="@/assets/img/website.png" class="img-fluid" alt="Logo" />
          </router-link>
          <router-link to="/index" class="navbar-brand logo-small">
            <img src="@/assets/img/website.png" class="img-fluid" alt="Logo" style="  width:70px;" />
          </router-link>
        </div>
        <div class="main-menu-wrapper">


          <div class="menu-header" >

            <router-link to="/index" class="menu-logo">
              <img src="@/assets/img/logo-small-ba2.jpg" alt="Logo" style="  width:70px; margin-left: 60px;" />
            </router-link>
            <a
              id="menu_close"
              class="menu-close"
              href="#"
              @click="closeSidebar"
            >
              <i class="fas fa-times"></i
            ></a>
          </div>
        <usermenu></usermenu>
        </div>

      </nav>
    </div>
  </header>

  <!-- /Header -->
</template>

<script>

export default {
  data() {
    return {
      isSidebarOpen: true,
    };
  },
    methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      document.documentElement.classList.toggle("menu-opened");
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      document.documentElement.classList.remove("menu-opened");
    },
  },
};
</script>
