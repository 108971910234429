<template>
  <ul class="main-nav">
    <li class="active">
      <a href="/index">Home</a>
    </li>
    <li>
      <a href="/listing/listing-grid">Our fleet</a>
    </li>
    <li>
      <a href="/listing/sunset-details">Sunset Trip</a>
    </li>
    <li>
      <a href="/listing/sup-details">Paddle Board</a>
    </li>
    <li>
      <a href="/pages/about-us">About Us</a>
    </li>
    <li>
      <a href="/pages/gallery">Gallery</a>
    </li>
    <li>
      <a href="/contact-us">Contact</a>
    </li>
  </ul>
</template>
<script setup lang="ts">
</script>