<template>
  <div class="row">
    <div class="col-md-12">
      <div class="details-car-grid">

          <div class="price-list">
            <h1>Our Price List</h1>
            <table>
              <thead>
              <tr>
                <th>Service</th>
                <th>Price</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in priceList" :key="index">
                <td>{{ item.service }}</td>
                <td>{{ item.price }}</td>
              </tr>
              </tbody>
            </table>
          </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      priceList: [
        { service: 'Boat Rental - 2 Hour', price: '150€' },
        { service: 'Boat Rental - 4 Hour', price: '240€' },
        { service: 'Boat Rental - 6 Hour', price: '310€' },
        { service: 'Boat Rental - 8 Hour', price: '390€' }
      ]
    };
  }
};
</script>
<style scoped>
.price-list {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.price-list h1 {
  text-align: center;
  margin-bottom: 20px;
}

.price-list table {
  width: 100%;
  border-collapse: collapse;
}

.price-list th, .price-list td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.price-list th {
  background-color: #f2f2f2;
}
</style>