<template>
  <div class="col-lg-4 theiaStickySidebar">
    <div class="stickysidebar">
      <div class="review-sec mt-0">

        <div class="review-header">
          <h4>Check Availability</h4>
        </div>
        <div class="">
          <form id="sup">
            <ul>
              <li class="column-group-main">
                <div class="input-block m-0">
                  <label>Pickup Date</label>
                </div>
                <div class="input-block-wrap sidebar-form">
                   <div class="input-block me-2">
                  <div class="group-img">
                    <input type="date" id="date" name="date" required min="new Date()" style="margin: 0.5rem; padding: 0.8rem; width: 80%; border-radius: 5px; background:#FCFBFB; border:1px solid #F4F4F4;">

                  </div>
                </div>

                </div>
              </li>
              <li class="column-group-main">
                <div class="input-block">
                  <label>Number of SUPs</label>
                  <div class="group-img">
                    <input
                        class="form-control"
                        placeholder=""
                        type="text"
                        name="Sup"
                        required
                    />
                  </div>
                  <label>How many hours do you need it?</label>
                  <div class="input-block me-2">

                    <div class="form-group">
                      <select id="option" class="form-control" name="boats" required>
                        <option style="display:none"></option>
                        <option>Full Day</option>
                        <option>Half Day</option>
                      </select>
                    </div>

                  </div>
                </div>
              </li>

              <li class="column-group-main">
                <div class="input-block">
                  <label>Full Name</label>
                  <div class="group-img">
                    <input
                        class="form-control"
                        name="name"
                        placeholder=""
                        type="text"
                        required
                    />
                  </div>
                  <label>E-mail</label>
                  <div class="group-img">
                    <input
                        class="form-control"
                        name="email"
                        placeholder=""
                        type="Email"
                        required
                    />
                  </div>
                  <label>Phone Number</label>
                  <div class="group-img">
                    <input
                        class="form-control"
                        name="number"
                        placeholder=""
                        type="tel"
                        required
                    />
                  </div>
                </div>
              </li>


              <li class="column-group-last">
                <div class="input-block mb-0">
                  <div class="search-btn">
                    <button class="btn btn-primary check-available w-100">
                      Check Availability
                    </button>

                  </div>
                </div>
              </li>
            </ul>
          </form>
        </div>
      </div>
      <div class="review-sec share-car mt-0">
        <div class="review-header">
          <h4>Our Price List</h4>
        </div>
        <div class="price-list">
          <table>
            <thead>
            <tr>
              <th>Service</th>
              <th>Price</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in priceList" :key="index">
              <td>{{ item.service }}</td>
              <td>{{ item.price }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {ref} from 'vue'

const currentDate1 = ref(new Date());
//import { add } from 'date-fns'

export default {
  data() {
    return {
      startdate1: currentDate1,
      dateFormat: "dd-MM-yyyy",
      priceList: [
        {service: 'half day 4 hours', price: '20'},
        {service: 'full day 8 hours', price: '30'},
      ]
    };
  }
};
//const disabledDate1 = ref(new Date("2024-5-20"))
//<!--:disabledDates="{ dates: [disabledDate1,new Date('2024-5-28')] }" -->



// let disabledDate = ref((new Date()))[]
// disabledDate.value = new Date("2024-5-20")+new Date("2024-5-22");


</script>
<style scoped>
.price-list {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.price-list h1 {
  text-align: center;
  margin-bottom: 20px;
}

.price-list table {
  width: 100%;
  border-collapse: collapse;
}

.price-list th, .price-list td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.price-list th {
  background-color: #f2f2f2;
}
</style>