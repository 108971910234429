<template>
  <div class="row">
    <div class="col-md-12">
      <div class="details-car-grid">
        <div class="details-slider-heading">
          <h3>You May be Interested in</h3>
        </div>
        <div class="car-details-slider owl-carousel">
          <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
            <Slide v-for="item in Listing" :key="item.id">
              <div class="card">
                <div class="listing-item pb-0">
                  <div class="listing-img">
                    <router-link to="/listing/listing-details">
                      <img
                        :src="require(`@/assets/img/cars/${item.image}`)"
                        class="img-fluid"
                        alt="KIA"
                      />
                    </router-link>
                    <div class="fav-item">
                      <span class="featured-text">{{ item.make }}</span>
                      <a href="javascript:void(0)" class="fav-icon"
                      @click="toggleSelected(item)"
                      :class="{ selected: item.isSelected }">
                        <i class="feather-heart"></i>
                      </a>
                    </div>
                  </div>
                  <div class="listing-content">
                    <div class="listing-features">
                      <div class="author-img">
                        <img
                          :src="require(`@/assets/img/profiles/${item.profile_image}`)"
                          alt="author"
                        />
                      </div>
                      <h3 class="listing-title">
                        <a href="javascript:void(0)">{{ item.Name }}</a>
                      </h3>
                      <div class="list-rating">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled"></i>
                        <span>(5.0)</span>
                      </div>
                    </div>
                    <div class="listing-details-group">
                      <ul>
                        <li>
                          <span
                            ><img src="@/assets/img/icons/car-parts-05.svg" alt="Auto"
                          /></span>
                          <p>{{ item.transmission }}</p>
                        </li>
                        <li>
                          <span
                            ><img src="@/assets/img/icons/car-parts-02.svg" alt="22 KM"
                          /></span>
                          <p>{{ item.mileage }}</p>
                        </li>
                        <li>
                          <span
                            ><img src="@/assets/img/icons/car-parts-03.svg" alt="Petrol"
                          /></span>
                          <p>{{ item.fuel_type }}</p>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <span
                            ><img src="@/assets/img/icons/car-parts-04.svg" alt="Diesel"
                          /></span>
                          <p>{{ item.power }}</p>
                        </li>
                        <li>
                          <span
                            ><img src="@/assets/img/icons/car-parts-05.svg" alt="2016"
                          /></span>
                          <p>{{ item.year }}</p>
                        </li>
                        <li>
                          <span
                            ><img src="@/assets/img/icons/car-parts-06.svg" alt="Persons"
                          /></span>
                          <p>{{ item.persons }}</p>
                        </li>
                      </ul>
                    </div>
                    <div class="listing-location-details">
                      <div class="listing-price">
                        <span class="me-2"><i class="feather-map-pin"></i></span>
                        {{ item.location }}
                      </div>
                      <div class="listing-price">
                        <h6>{{ item.price }} <span>/ Day</span></h6>
                      </div>
                    </div>
                    <div class="listing-button">
                      <router-link to="/listing/listing-details" class="btn btn-order"
                        ><span><i class="feather-calendar me-2"></i></span>Rent
                        Now</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import Listing from "@/assets/json/listing.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      Listing: Listing,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  methods: {
    toggleSelected(item) {
      item.isSelected = !item.isSelected;
    },
  },
};
</script>
