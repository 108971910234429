<template>
  <div class="row">
    <!-- col -->
    <div
      class="col-lg-4 col-md-6 col-12"
      v-for="item in Toyota"
      :key="item.id"
      data-aos="fade-down"
    >
      <div class="listing-item">
        <div class="listing-img">
          <router-link to="/listing/listing-details">
            <img
              :src="require(`@/assets/img/cars/${item.Image}`)"
              class="img-fluid"
              alt="Toyota"
            />
          </router-link>
          <div class="fav-item">
            <span class="featured-text">{{ item.Title }}</span>
            <a
              href="javascript:void(0)"
              class="fav-icon"
              @click="toggleSelected(item)"
              :class="{ selected: item.isSelected }"
            >
              <i class="feather-heart"></i>
            </a>
          </div>
        </div>
        <div class="listing-content">
          <div class="listing-features">
<!--            <a href="javascript:void(0)" class="author-img">-->
<!--              <img :src="require(`@/assets/img/profiles/${item.Avatar}`)" alt="author" />-->
<!--            </a>-->
            <h3 class="listing-title">
              <router-link to="/listing/listing-details">Acura RDX FWD</router-link>
            </h3>
            <div class="list-rating">
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled"></i>
              <span>(5.0)</span>
            </div>
          </div>
          <div class="listing-details-group">
            <ul>
              <li>
                <span
                  ><img :src="require(`@/assets/img/icons/${item.Gear}`)" alt="Auto"
                /></span>
                <p>{{ item.Drive }}</p>
              </li>
              <li>
                <span
                  ><img src="@/assets/img/icons/car-parts-02.svg" alt="22 miles"
                /></span>
                <p>{{ item.KM }}</p>
              </li>
              <li>
                <span
                  ><img src="@/assets/img/icons/car-parts-03.svg" alt="Petrol"
                /></span>
                <p>{{ item.liquid }}</p>
              </li>
            </ul>
            <ul>
              <li>
                <span><img src="@/assets/img/icons/car-parts-04.svg" alt="Power" /></span>
                <p>{{ item.Steering }}</p>
              </li>
              <li>
                <span><img src="@/assets/img/icons/car-parts-05.svg" alt="2019" /></span>
                <p>{{ item.Engine }}</p>
              </li>
              <li>
                <span
                  ><img src="@/assets/img/icons/car-parts-06.svg" alt="Persons"
                /></span>
                <p>{{ item.Seat }}</p>
              </li>
            </ul>
          </div>
          <div class="listing-location-details">
            <div class="listing-price">
              <span><i class="feather-map-pin"></i></span>{{ item.Location }}
            </div>
            <div class="listing-price">
              <h6>${{ item.Price }} <span>/ Day</span></h6>
            </div>
          </div>
          <div class="listing-button">
            <router-link :to="(`/listing/listing-details${item.id}`)" class="btn btn-order"
            ><span><i class="feather-calendar me-2"></i></span>Rent
              Now</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <!-- /col -->
  </div>
</template>

<script>
import Toyota from "@/assets/json/toyota.json";
export default {
  data() {
    return {
      Toyota: Toyota,
    };
  },
  methods: {
    toggleSelected(item) {
      item.isSelected = !item.isSelected;
    },
  },
};
</script>
