<template>
  <layout-header></layout-header>
  <breadcrumb :title="title" :text="text" :text1="text1" />
  <section class="section pricing-section pricing-page">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-4 col-md-4 col-12"
          data-aos="fade-down"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <div class="price-card">
            <div class="price-head">
              <div class="price-level">
                <h6>Essential</h6>
                <p>For the basics</p>
              </div>
              <h4>$49</h4>
              <span>Per user per month</span>
            </div>
            <div class="price-details">
              <ul>
                <li class="price-check">
                  <span><i class="fa-regular fa-circle-check"></i></span>Get Starting
                  message
                </li>
                <li class="price-check">
                  <span><i class="fa-regular fa-circle-check"></i></span>Min 1 month,
                  extend anytime
                </li>
                <li class="price-check">
                  <span><i class="fa-regular fa-circle-check"></i></span>Rental car prices
                  include tax
                </li>
                <li class="price-uncheck">
                  <span><i class="fa-regular fa-circle-xmark"></i></span>Extend or return
                  anytime
                </li>
                <li class="price-uncheck">
                  <span><i class="fa-regular fa-circle-xmark"></i></span>Doorstep delivery
                  in 2days
                </li>
                <li class="price-uncheck">
                  <span><i class="fa-regular fa-circle-xmark"></i></span>Car system
                  included free of charge.
                </li>
                <li class="price-uncheck">
                  <span><i class="fa-regular fa-circle-xmark"></i></span>Min 1 month,
                  extend anytime
                </li>
              </ul>
              <div>
                <router-link to="/index" class="btn viewdetails-btn"
                  >Request a demo</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-4 col-12"
          data-aos="fade-down"
          data-aos-duration="1200"
          data-aos-delay="300"
        >
          <div class="price-card price-card-popular">
            <div class="price-head">
              <div class="price-level price-level-popular">
                <h6>Recommended</h6>
                <p>For the Users</p>
              </div>
              <h4>$95</h4>
              <span>Per user per month</span>
            </div>
            <div class="price-details">
              <ul>
                <li class="price-check">
                  <span><i class="fa-regular fa-circle-check"></i></span>Get Starting
                  message
                </li>
                <li class="price-check">
                  <span><i class="fa-regular fa-circle-check"></i></span>Min 1 month,
                  extend anytime
                </li>
                <li class="price-check">
                  <span><i class="fa-regular fa-circle-check"></i></span>Rental car prices
                  include tax
                </li>
                <li class="price-check">
                  <span><i class="fa-regular fa-circle-check"></i></span>Extend or return
                  anytime
                </li>
                <li class="price-uncheck">
                  <span><i class="fa-regular fa-circle-xmark"></i></span>Doorstep delivery
                  in 2days
                </li>
                <li class="price-uncheck">
                  <span><i class="fa-regular fa-circle-xmark"></i></span>Car system
                  included free of charge.
                </li>
                <li class="price-uncheck">
                  <span><i class="fa-regular fa-circle-xmark"></i></span>Min 1 month,
                  extend anytime
                </li>
              </ul>
              <div>
                <router-link to="/index" class="btn viewdetails-btn-popular"
                  >Request a demo</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-4 col-12"
          data-aos="fade-down"
          data-aos-duration="1200"
          data-aos-delay="400"
        >
          <div class="price-card">
            <div class="price-head">
              <div class="price-level">
                <h6>Pro</h6>
                <p>For the Pro</p>
              </div>
              <h4>$154</h4>
              <span>Per user per month</span>
            </div>
            <div class="price-details">
              <ul>
                <li class="price-check">
                  <span><i class="fa-regular fa-circle-check"></i></span>Get Starting
                  message
                </li>
                <li class="price-check">
                  <span><i class="fa-regular fa-circle-check"></i></span>Min 1 month,
                  extend anytime
                </li>
                <li class="price-check">
                  <span><i class="fa-regular fa-circle-check"></i></span>Rental car prices
                  include tax
                </li>
                <li class="price-check">
                  <span><i class="fa-regular fa-circle-check"></i></span>Extend or return
                  anytime
                </li>
                <li class="price-check">
                  <span><i class="fa-regular fa-circle-check"></i></span>Doorstep delivery
                  in 2days
                </li>
                <li class="price-check">
                  <span><i class="fa-regular fa-circle-check"></i></span>Car system
                  included free of charge.
                </li>
                <li class="price-check">
                  <span><i class="fa-regular fa-circle-check"></i></span>Min 1 month,
                  extend anytime
                </li>
              </ul>
              <div>
                <router-link to="/index" class="btn viewdetails-btn"
                  >Request a demo</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <layout-footer></layout-footer>
</template>

<script>
export default {
  data() {
    return {
      title: "Pricing Plan",
      text: "Pages",
      text1: "Pricing Plan",
    };
  },
};
</script>
