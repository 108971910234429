<template>
  <section class="section services bg-light-primary">
    <div class="service-right">
      <img
        src="@/assets/img/bg/service-right.svg"
        class="img-fluid"
        alt="services right"
      />
    </div>
    <div class="container">
      <!-- Heading title-->
      <div class="section-heading" data-aos="fade-down">
        <h2>How It Works</h2>
        <p>
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
        </p>
      </div>
      <!-- /Heading title -->
      <div class="services-work">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12" data-aos="fade-down">
            <div class="services-group">
              <div class="services-icon border-secondary">
                <img
                  class="icon-img bg-secondary"
                  src="@/assets/img/icons/services-icon-01.svg"
                  alt="Choose Locations"
                />
              </div>
              <div class="services-content">
                <h3>1. Choose Locations</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s,
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12" data-aos="fade-down">
            <div class="services-group">
              <div class="services-icon border-warning">
                <img
                  class="icon-img bg-warning"
                  src="@/assets/img/icons/services-icon-02.svg"
                  alt="Choose Locations"
                />
              </div>
              <div class="services-content">
                <h3>2. Pick-Up Locations</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s,
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12" data-aos="fade-down">
            <div class="services-group">
              <div class="services-icon border-dark">
                <img
                  class="icon-img bg-dark"
                  src="@/assets/img/icons/services-icon-03.svg"
                  alt="Choose Locations"
                />
              </div>
              <div class="services-content">
                <h3>3. Book your Car</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s,
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
