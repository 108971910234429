<template>
  <div class="sortby-sec">
    <div class="container">
      <div class="sorting-div">
        <div class="row d-flex align-items-center">
          <div class="col-xl-4 col-lg-3 col-sm-12 col-12">
            <div class="count-search">
              <p>Showing <span>1-8</span> of 10 Results</p>
            </div>
          </div>
          <div class="col-xl-8 col-lg-9 col-sm-12 col-12">
            <div class="product-filter-group">
              <div class="sortbyset">
                <span class="sortbytitle">Show : </span>
                <div class="sorting-select select-one">
                  <vue-select :options="Numberlist" id="numberlist" placeholder="5" />
                </div>
                <div class="sorting-select select-two">
                  <vue-select
                    :options="Listlevel"
                    id="listlevel"
                    placeholder="Low to High"
                  />
                </div>
                <div class="sorting-select select-three">
                  <vue-select :options="Carlist" id="carlist" placeholder="Popular" />
                </div>
              </div>
              <div class="grid-listview">
                <ul>
                  <li>
                    <router-link
                      to="/listing/listing-grid"
                      :class="{ active: isGrid }"
                      @click="toggleGrid"
                    >
                      <i class="feather-grid"></i>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      to="listing-list"
                      :class="{ active: isList }"
                      @click="toggleList"
                    >
                      <i class="feather-list"></i>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Carlist: [
        "Popular",
        "Toyota Camry SE 350",
        "Audi A3 2019 new",
        "Ferrari 458 MM Speciale",
        "Chevrolet Camaro",
        "Acura Sport Version",
      ],
      Numberlist: ["5", "10", "15", "20"],
      Listlevel: ["Low to High", "High to Low"],
      isList: false,
      isGrid: false,
    };
  },
  methods: {
    toggleList() {},
    toggleGrid() {},
  },
};
</script>
