<template>
  <div class="review-sec specification-card">
    <div class="review-header">
      <h4>Specifications</h4>
    </div>
    <div class="card-body">
      <div class="lisiting-featues">
        <div class="row">
          <div
            class="featureslist d-flex align-items-center col-lg-3 col-md-4"
            v-for="item in Listing_Specification"
            :key="item.id"
          >
            <div class="feature-img">
              <img :src="require(`@/assets/img/specification/${item.img}`)" alt="" />
            </div>
            <div class="featues-info">
              <span>{{ item.name }} </span>
              <h6>{{ item.value }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Listing_Specification from "@/assets/json/listing-specification3.json";
export default {
  data() {
    return {
      Listing_Specification: Listing_Specification,
    };
  },
};
</script>
